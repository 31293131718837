import React from 'react';
import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";


function ListedSite({id, image, title, desc, locationId}) {

	const Container = styled(NavLink)`
      display: flex;
      flex-direction: column;

      & img {
        width: 100%;
        object-fit: cover;
      }

      & div {
        padding: 15px 30px 15px;
      }

      & h2 {
        font-size: 32pt;
        font-family: 'Playfair Display', serif;
        margin-top: 8px;
        margin-bottom: 0;
        color: ${({theme}) => theme.colors.theme1};
      }

      & p {
        font-size: 16pt;
        margin-top: 0;
        margin-bottom: 0;
        color: black;

        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
	`;

	return (
		<Container to={"site/" + id}>
			<img src={image} alt={title}/>

			<div>
				<h2>{title}</h2>

				<p>
					{desc}
				</p>
			</div>

		</Container>
	);
}

export default ListedSite;
