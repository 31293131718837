import React, {useEffect, useState} from 'react';
import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";
import ListedLocation from "../components/ListedLocation";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination, Navigation, FreeMode} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "./myswiper.css";



export const PrimaryButton = styled(NavLink)`
  background-color: #000;
  color: #FFF;
  padding: 12px 32px;
  position: relative;
  font-size: 16pt;
  z-index: 99;

  @media screen and (max-width: 770px) {
    font-size: 12pt;
  }

  &::before {
    width: 95%;
    height: 85%;
    content: '';
    position: absolute;
    top: 15%;
    left: -4.5%;
    border: 3px solid #000;
    background-color: transparent;
    transition: all 0.5s;
    z-index: -1;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover::before {
    top: 20%;
    left: -6.5%;
  }
`;

function Home(props) {

	const Hero = styled('div')`
      position: relative;
      height: 100vh;
      width: 100%;
      z-index: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;

      &::after {
        content: '';
        background: linear-gradient(#002200aa, #00220044);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -2;
      }

      & h1 {
        color: ${props => props.theme.colors.theme2};
        text-align: center;
        margin: 0;
      }

      & p {
        text-align: center;
        font-size: 18pt;
      }

      @media screen and (max-width: 770px) {
        & p {
          font-size: 12pt;
        }
      }
	`;


	const [projects, setProjects] = useState([]);

	const [developers, setDevelopers] = useState([]);


	useEffect(() => {
		function getItems() {

			fetch(`https://theplaceholding.com/apis/get-locations.php`, {
				headers:
					{
						"Accept": "application/json",
					},
			}).then(function (res) {
				return res.json();
			}).then(function (data) {
				if (data.success == "1") {
					setProjects(data.locations);
					getDevelopers();
				}
			});

		}

		function getDevelopers() {
			fetch(`https://theplaceholding.com/apis/get-developer.php`, {
				headers:
					{
						"Accept": "application/json",
					},
			}).then(function (res) {
				return res.json();
			}).then(function (data) {
				if (data.success == "1") {
					setDevelopers(data.developers);
				}
			});
		}

		getItems();
	}, []);

	return (
		<>

			<Hero>

				<Swiper className="mySwiper heroSwiper" style={{
					position: "absolute",
					top: 0,
					left: 0,
					zIndex: -2,
					width: "100%",
				}} slidesPerView={1} loop={true} autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}} speed={1000} modules={[Autoplay, Pagination, Navigation]}>
					<SwiperSlide style={{height: "100vh"}}><img alt={""} style={{objectFit: "cover"}} src={"saving-01.jpg"}/></SwiperSlide>
					<SwiperSlide style={{height: "100vh"}}><img alt={""} style={{objectFit: "cover"}} src={"finances-2.png"}/></SwiperSlide>
					<SwiperSlide style={{height: "100vh"}}><img alt={""} style={{objectFit: "cover"}} src={"finances-3.png"}/></SwiperSlide>
					<SwiperSlide style={{height: "100vh"}}><img alt={""} style={{objectFit: "cover"}} src={"finances-4.png"}/></SwiperSlide>
					<SwiperSlide style={{height: "100vh"}}><img alt={""} style={{objectFit: "cover"}} src={"finances-5.png"}/></SwiperSlide>
				</Swiper>

				<h1>
					Get up to $10,000 in rebates.
				</h1>

				<p>
					We will provide you with the best appliances options available <br/> contact us and we can get started
					with you right away.
				</p>

				<PrimaryButton to={"contact"}>
					Contact us now
				</PrimaryButton>
			</Hero>

			<div style={{marginTop: 64}}>
				<Swiper className="mySwiper" style={{transitionTimingFunction: "linear"}} freeMode={{enabled: true, momentumBounce: false}} spaceBetween={30} slidesPerView={8} breakpoints={{300: {slidesPerView: 2}, 480: {slidesPerView: 3}, 600: {slidesPerView: 4}, 850: {slidesPerView: 5}, 1200: {slidesPerView: 6}, 1400: {slidesPerView: 7}}} loop={true} autoplay={{delay: 1, disableOnInteraction: false}} speed={5000} modules={[Autoplay, FreeMode]}>
					{developers.map((x) => {
						return <SwiperSlide style={{display: "flex",filter: "grayscale(0.6)"}}><img style={{height: 60}} src={x.image} title={x.name} alt={x.name}/></SwiperSlide>;
					})}
				</Swiper>
			</div>

			<div className={"container"}>
				<h1>
					Products we offer
				</h1>

				<div className={"locations-container"}>
					{
						projects.filter((location) => {
							return location.homePage === "1";
						}).map((location) => {
							return <ListedLocation key={location.id} id={location.id} title={location.name} image={location.image} numberOfSites={location.sitesCount}/>;
						})
					}
				</div>
			</div>

		</>
	);
}

export default Home;
