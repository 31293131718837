import React from 'react';
import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";


function ListedLocation({id, image, title, numberOfSites}) {

	const Container = styled(NavLink)`
      display: flex;
      flex-direction: column;
      align-items: center;

      & img {
        width: 100%;
        object-fit: cover;
      }

      & h2 {
        font-size: 32pt;
        font-family: 'Playfair Display', serif;
        margin-top: 8px;
        margin-bottom: 0;
        color: ${({theme}) => theme.colors.theme1};
      }

      & p {
        font-size: 16pt;
        margin-top: 0;
        margin-bottom: 0;
        color: black;
      }

	`;

	return (
		<Container to={"/location/" + id}>
			<img src={image} alt={title}/>

			<h2>{title}</h2>

			<p>
				{numberOfSites} Site {numberOfSites > 1 && "s"} in location
			</p>

		</Container>
	);
}

export default ListedLocation;
