import React from 'react';
import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";


function SidePageHead({bread}) {

	const Container = styled("div")`
      display: flex;
      background: url("/compressed-header.jpg");
      background-size: cover;
      width: 100%;
      min-height: 450px;
	`;

	const ContentContainer = styled("div")`
      padding-top: 200px;
      display: flex;
      flex-direction: column;
      color: ${({theme}) => theme.colors.theme2};
      z-index: 0;

	  & span {
		font-size: 18px;
        text-transform: capitalize;
	  }
	  
      & h1 {
        margin-top: 0;
        text-transform: capitalize;
      }
	`;


	return (<Container>

		<ContentContainer className={"container"}>
			<span>
				{bread.reduce((acc, curr) => {
					if (curr !== bread[bread.length - 1]){
						acc += curr;
						acc += " » ";
					}
					return acc;
				}, "")}
			</span>
			<h1>
				{bread[bread.length - 1]}
			</h1>


		</ContentContainer>

	</Container>);
}

export default SidePageHead;
