import React, {useState, useEffect} from 'react';
import ListedLocation from "../components/ListedLocation";
import SidePageHead from "../components/SidePageHead";


function Projects(props) {

	const [projects, setProjects] = useState([])

	useEffect(()=> {
		function getItems() {

			fetch(`https://theplaceholding.com/apis/get-locations.php`, {
				headers:
					{
						"Accept": "application/json",
					},
			}).then(function (res) {
				return res.json();
			}).then(function (data) {
				if (data.success == "1") {
					setProjects(data.locations);
				}
			});

		}

		getItems()
	}, []);

	return (
		<div>

			<SidePageHead bread={["home", "Products", "Discover and let our team handle the rest"]}/>

			<div className={"container"}>
				<h1>
					Products
				</h1>

				<div className={"locations-container"}>
					{
						projects.map((location) => {
							return <ListedLocation key={location.id} id={location.id} title={location.name} image={location.image} numberOfSites={location.sitesCount} />
						})
					}
				</div>
			</div>
		</div>
	);
}

export default Projects;
