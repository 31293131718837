import React, {useState} from 'react';
import SidePageHead from "../components/SidePageHead";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {NotificationManager} from "react-notifications";
import {useLocation} from "react-router-dom";


const PageContainer = styled('div')`
      display: flex;
      justify-content: space-evenly;
	  
	  @media screen and (max-width: 992px) {
		flex-direction: column;
		align-items: center;
	  }
	`;

const ContactInfo = styled('form')`
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      max-width: 600px;
	`;

const MyForm = styled('form')`
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      max-width: 600px;

      & h1 {
        text-align: center;
        margin-bottom: 12px;
      }

      & > div {
        display: flex;
      }
	`;

const InputBaseStyle = css`
      background-color: white;
      padding: 15px 20px;
      margin: 15px 10px;
      outline: none;
      border: 2px solid #c7c7c7;
      border-radius: 5px;
      flex-grow: 2;
      font-size: 12pt;
      font-family: Poppins, serif;
	`;

const Input = styled('input')`
      ${InputBaseStyle}
	`;

const TextArea = styled('textarea')`
      ${InputBaseStyle}
	`;

const Button = styled('input')`
      outline: none;
      border: none;
      background-color: ${({theme}) => theme.colors.theme1};
      color: white;
      font-family: Poppins, serif;
      font-size: 14pt;
      width: min(30%, 120px);
      padding: 15px 20px;
      margin: 15px 10px;
      border-radius: 5px;
	  cursor: pointer;
	  
      @media screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
      }
	`;

const SocialInfo = styled('div')`
      display: flex;
      gap: 12px;
      align-items: center;
      font-size: 14pt;
	`;

const SocialMediaHolder = styled('div')`
      display: flex;
      gap: 24px;
	  
	  & * {
		font-size: 36pt;
	  }
	`;


function Contact(props) {


	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");

	const location = useLocation()


	function handleSubmit(event) {
		event.preventDefault();

		const data = new FormData();
		//authentication
		data.append("token", localStorage.getItem("token"));

		data.append('name', name);
		data.append('email', email);
		data.append('number', number);
		data.append('subject', subject);
		data.append('message', message);

		location.state.siteId && data.append('siteId', location.state.siteId)


		fetch(`https://theplaceholding.com/apis/record-message.php`, {
			method: 'POST',
			body: data,
			headers:
				{
					"Accept": "application/json",
				},
		}).then(function (res) {
			return res.json();
		}).then(function (data) {
			if (data.success == "1") {
				NotificationManager.success("", 'تم', 5000, null, null, '');
				setName("")
				setMessage("")
				setSubject("")
				setEmail("")
				setNumber("")
			} else {
				NotificationManager.error("حدث خطاء ما.", 'خطاء غير معروف', 5000, null, null, '');
			}

		}).catch(function (error) {
			NotificationManager.error("حدث خطاء في الإتصال تأكد من اتصالك بالأنترنيت", 'خظاء في الإتصال', 5000, null, null, '');
		});

	}

	return (
		<div>
			<div>
				<SidePageHead bread={["home", "contact", "Let's work this out together"]}/>


				<PageContainer className={"container"} >
					<ContactInfo>
						<h1>
							Contact Info
						</h1>

						<div style={{display: "flex", flexDirection: "column", gap: 24}}>
							<SocialInfo>
								<FontAwesomeIcon icon={faEnvelope} size="2x"/>

								<a href={"mailto:sales@theplaceholding.com"} style={{color: "black"}}>
									sales@greenclimatesolutions.com
								</a>
							</SocialInfo>

							<SocialInfo>
								<FontAwesomeIcon icon={faPhone} size="2x"/>

								<a href={"tel:"} style={{color: "black"}}>
									(XXX) XXX-XXXX
								</a>
							</SocialInfo>
						</div>


						<h2 style={{marginTop: 64, marginBottom: 0}}>
							Social Media
						</h2>

						<SocialMediaHolder>
							<a href={"https://www.facebook.com"} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faFacebook} style={{color: "#3b5998"}} /></a>
							<a href={"https://www.instagram.com"} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} style={{color: "#DD2A7B"}}/></a>
							<a href={"https://eg.linkedin.com/company"} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} style={{color: "#0E76A8"}}/></a>
						</SocialMediaHolder>

					</ContactInfo>

					<MyForm onSubmit={handleSubmit}>
						<h1>
							Send a message
						</h1>

						<div>
							<Input placeholder={"Name*"} required type={"text"} style={{width: "50%"}} value={name} onChange={(e) => {setName(e.target.value)}}/>
							<Input placeholder={"Number*"} type={"text"} required style={{width: "50%"}} value={number} onChange={(e) => setNumber(e.target.value)}/>
						</div>

						<Input placeholder={"Email*"} required type={"email"} value={email} onChange={(e) => setEmail(e.target.value)} />

						<Input placeholder={"Subject*"} required value={subject} type={"text"} onChange={(e) => setSubject(e.target.value)}/>

						<TextArea placeholder={"Message"} value={message} onChange={(e) => setMessage(e.target.value)}/>

						<Button type={"submit"} value={"Done"}/>

					</MyForm>
				</PageContainer>


			</div>

		</div>
	);
}

export default Contact;
