import React from 'react';
import styled from "@emotion/styled";
import {NavLink, useNavigate, useOutletContext} from "react-router-dom";
import {PrimaryButton} from "./Home";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faCross} from "@fortawesome/free-solid-svg-icons";


const Container = styled('div')`
  background: white;
  padding: 12px;
  border-radius: 6px;
  width: min(100%, 800px);
  display: flex;
  flex-direction: column;
  position: relative;

  & h1 {
    margin: 0;
  }

  & img {
    width: 100%;
    max-height: 30vh;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
  }

  & a {
    align-self: center;
    margin-bottom: 12px;
  }
`;

const CloseContainer = styled('div')`
  position: absolute;
  height: 12px;
  width: 12px;
  right: -60px;
  top: 0;
  background: white;
  padding: 20px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    position: absolute;
    font-size: 16pt;
  }
`;


function Site() {

	const {locationId, name, locationName, locationText, image, desc, siteId} = useOutletContext();

	const navigator = useNavigate();

	return (
		<Container onClick={(e) => {
			// making sure it doesn't propagate to parent
			e.stopPropagation();
		}}>

			<CloseContainer onClick={() => {
				navigator("/location/" + locationId);
			}}>
				<FontAwesomeIcon icon={faClose}/>
			</CloseContainer>

			<img src={image} alt={"site location"}/>


			<h1>
				{name}
			</h1>
			<span>
				{locationName}
			</span>

			<p>
				{locationText}
			</p>

			<p>
				{desc}
			</p>

			<PrimaryButton
				to={"/contact"}
				state={{siteId: siteId}}
			>
				Contact us now
			</PrimaryButton>

		</Container>
	);
}

export default Site;
