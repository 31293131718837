import React, {useEffect, useState} from 'react';
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {NotificationManager} from "react-notifications";
import {useLocation} from "react-router-dom";

const OuterContainer = styled(`div`)`
      background-repeat: no-repeat !important;
      background-size: cover !important;
      min-height: 100vh;
      position: relative;
      z-index: 0;
      display: flex;
      align-items: start;
	  color: white;

      &::after {
        content: '';
        background: linear-gradient(#00000077, #00000011);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -2;
      }

	`;

const PageContainer = styled('div')`
      display: flex;
      justify-content: space-evenly;

      @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: center;
      }
	`;

const ContactInfo = styled('form')`
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 2;
      max-width: 600px;
	`;

const MyForm = styled('form')`
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      max-width: 600px;

      & h1 {
        text-align: center;
        margin-bottom: 12px;
      }

      & > div {
        display: flex;
      }
	`;

const InputBaseStyle = css`
      background-color: white;
      padding: 15px 20px;
      margin: 15px 10px;
      outline: none;
      border: 2px solid #c7c7c7;
      border-radius: 5px;
      flex-grow: 2;
      font-size: 12pt;
      font-family: Poppins, serif;
	`;

const Input = styled('input')`
      ${InputBaseStyle}
	`;

const TextArea = styled('textarea')`
      ${InputBaseStyle}
	`;

const Button = styled('input')`
      outline: none;
      border: none;
      background-color: ${({theme}) => theme.colors.theme1};
      color: white;
      font-family: Poppins, serif;
      font-size: 14pt;
      width: min(30%, 150px);
      padding: 15px 20px;
      margin: 15px 10px;
      border-radius: 5px;
      cursor: pointer;

      @media screen and (max-width: 992px) {
        margin-left: auto;
        margin-right: auto;
      }
	`;

const SocialInfo = styled('div')`
      display: flex;
      gap: 12px;
      align-items: center;
      font-size: 14pt;
	`;

const SocialMediaHolder = styled('div')`
      display: flex;
      gap: 24px;

      & * {
        font-size: 36pt;
      }
	`;

function ContactSite(props) {

	const [site, setSite] = useState(null);

	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");

	const [submited, setSubmited] = useState(false);


	function handleSubmit(event) {
		event.preventDefault();

		const data = new FormData();

		data.append('name', name);
		data.append('email', email);
		data.append('number', number);
		data.append('subject', subject);
		data.append('message', message);

		data.append('siteId', params.search.substring(3));


		fetch(`https://theplaceholding.com/apis/record-message.php`, {
			method: 'POST',
			body: data,
			headers:
				{
					"Accept": "application/json",
				},
		}).then(function (res) {
			return res.json();
		}).then(function (data) {
			if (data.success == "1") {
				NotificationManager.success("", 'Done', 5000, null, null, '');
				setName("");
				setMessage("");
				setSubject("");
				setEmail("");
				setNumber("");

				setSubmited(true)
			} else {
				NotificationManager.error("حدث خطاء ما.", 'خطاء غير معروف', 5000, null, null, '');
			}

		}).catch(function (error) {
			NotificationManager.error("حدث خطاء في الإتصال تأكد من اتصالك بالأنترنيت", 'خظاء في الإتصال', 5000, null, null, '');
		});

	}

	let params = useLocation();

	useEffect(() => {
		let formdata = new FormData();
		formdata.append("siteId", params.search.substring(3));

		let requestOptions = {
			method: 'POST',
			body: formdata,
			redirect: 'follow',
		};

		fetch(`https://theplaceholding.com/apis/get-sites-by-id.php`, requestOptions)
			.then(function (res) {
				return res.json();
			}).then(function (data) {
			if (data.success === 1) {
				setSite(data.site);
			}
		});
	}, [params.search]);


	return (
		<OuterContainer style={{background: `url(${site?.image})`}}>
			<PageContainer className={"container"}>
				<ContactInfo>
					<div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
						<h1>
							{site?.name}
						</h1>
						<span>
							{site?.locationName}
						</span>
						<p>
							{site?.locationText}
						</p>

						<p>
							{site?.desc}
						</p>
					</div>
				</ContactInfo>
				<MyForm onSubmit={handleSubmit}>
					<h1>
						Send a message
					</h1>

					{submited && <span style={{color: "#15ff15", fontWeight: 500, fontSize: 18}}>Thank you for your submit</span>}

					<div>
						<Input placeholder={"Name*"} required type={"text"} style={{width: "50%"}} value={name} onChange={(e) => {
							setName(e.target.value);
						}}/>
						<Input placeholder={"Number*"} type={"text"} required style={{width: "50%"}} value={number} onChange={(e) => setNumber(e.target.value)}/>
					</div>

					<Input placeholder={"Email"} type={"email"} value={email} onChange={(e) => setEmail(e.target.value)}/>

					<TextArea placeholder={"Message"} value={message} onChange={(e) => setMessage(e.target.value)}/>

					<Button type={"submit"} value={"Interested"}/>

				</MyForm>
			</PageContainer>
		</OuterContainer>
	);
}

export default ContactSite;
