import Header from "./components/Header";
import {Outlet} from "react-router-dom";
import {Global} from "@emotion/react";
import Footer from "./components/Footer";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


function App() {


	return (
		<>

			<Global
				styles={{
					'.locations-container': {
						marginTop: 50,
						display: "grid",
						boxSizing: "border-box",
						gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr))",
						alignItems: "start",
						gap: 30,

						"& > div": {
							height: "100%",
						},
					},
				}}
			/>

			<Header/>

			<Outlet/>

			<Footer />

			<NotificationContainer/>

		</>
	);
}

export default App;
