import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@emotion/react";
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";
import Home from "./views/Home";
import Location from "./views/Location";
import Contact from "./views/Contact";
import About from "./views/About";
import Projects from "./views/Projects";
import Site from "./views/Site";
import ContactSite from "./views/ContactSite";


const theme = {
	colors: {
		theme1: "#2A3D3A",
		theme2: "#8DC7A2",
		theme3: "#6C8D7A",
		theme4: "#54736A",
		theme5: "#4C6359",

		whiteBackground: "#fefefe",
		mainTextColor: "#000",
	}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Routes>
					<Route path="/contactsite" element={<ContactSite />} />
					<Route path="/" element={<App />}>
						<Route index element={<Home />} />
						<Route path="location">
							<Route path=":locationId" element={<Location />} >
								<Route path={"site"}>
									<Route path=":siteId" element={<Site />} />
								</Route>
							</Route>
						</Route>
						<Route path="/projects" element={<Projects />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/about" element={<About />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
