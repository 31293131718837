import React, {useState} from 'react';
import SidePageHead from "../components/SidePageHead";
import styled from "@emotion/styled";

const AboutContainer = styled('div')`
  display: flex;
  gap: 100px;
  
  @media screen and (max-width: 1200px) {
	flex-direction: column-reverse;
	align-items: center;
	gap: 0;
	margin-top: 100px;
  }
`;

function About(props) {

	return (
		<div>

			<SidePageHead bread={["home", "About", "Our Story"]}/>

			<AboutContainer className={"container"}>

				<div>
					<h1 style={{maxWidth: 500}}>
						About Green climate solutions
					</h1>

					<p style={{maxWidth: 500, fontSize: "18pt"}}>
						Some text a lot hopefully don't pay attention to this just think what you can write
						Some text a lot hopefully don't pay attention to this just think what you can write
						Some text a lot hopefully don't pay attention to this just think what you can write
						Some text a lot hopefully don't pay attention to this just think what you can write
						Some text a lot hopefully don't pay attention to this just think what you can write
					</p>
				</div>

				<img src={"logo-wide.png"} alt={"company logo"} style={{
					width: "min(600px, 50%)",
					objectFit: "contain",
				}}/>

			</AboutContainer>

		</div>
	);
}

export default About;
