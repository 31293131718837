import styled from "@emotion/styled";
import SidePageHead from "../components/SidePageHead";
import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ListedSite from "../components/ListedSite";
import {Outlet} from "react-router-dom";


function Location(props) {

	const [sites, setSites] = useState([]);

	let params = useParams();

	const navigation = useNavigate()

	const getLocationData = useCallback(function getLocationData() {
		let formdata = new FormData();
		formdata.append("locationId", params.locationId);

		let requestOptions = {
			method: 'POST',
			body: formdata,
			redirect: 'follow',
		};

		fetch(`https://theplaceholding.com/apis/get-sites-by-location-id.php`, requestOptions)
			.then(function (res) {
				return res.json();
			}).then(function (data) {
			if (data.success === 1) {
				setSites(data.sites);
			}
		});

	}, [params.locationId]);

	useEffect(() => {
		getLocationData();
	}, [getLocationData]);


	const PageHeader = styled("div")`
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -50px;

      & div {
        width: fit-content;
        padding: 15px 100px;
        background: ${({theme}) => theme.colors.theme5};
      }

      & h1 {
        margin: 0;
        color: white;
      }
	`;

	const SiteContainer = styled('div')`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: saturate(180%) blur(6px);
      z-index: 99999;
      display: flex;
      justify-content: center;
      align-items: center;
	`;


	return (
		<div>
			<SidePageHead bread={["home", "location", sites[0]?.locationName]}/>

			<PageHeader>
				<div>
					<h1>
						Sites
					</h1>
				</div>
			</PageHeader>

			<div className={"locations-container container"}>
				{sites.map((site) => {
					return <ListedSite key={site.id} id={site.id} image={site.image} title={site.name} desc={site.desc} locationId={params.locationId}/>;
				})}
			</div>

			{params.siteId && <SiteContainer onClick={() => navigation("/location/" + params.locationId)}>
				<Outlet context={{...sites.find((x) => x.id == params.siteId), siteId: params.siteId}} />
			</SiteContainer>}


		</div>
	);
}

export default Location;
