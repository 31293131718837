import {NavLink} from "react-router-dom";
import styled from "@emotion/styled";
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faLinkedin} from "@fortawesome/free-brands-svg-icons";

function Footer(props) {

	const MainContainer = styled('div')`
      box-shadow: 0 2px 2px #0000002A;
      width: 100%;
      margin-top: 50px;
      background: ${({theme}) => theme.colors.theme1};
      color: white;

      & h2 {
        font-family: 'Playfair Display', serif;
        font-size: 24pt;
        margin: 0 0 12px;
      }

      & a {
        color: white;
        font-size: 14pt;
      }
	`;

	const InnerContainer = styled('div')`
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: space-between;
      padding-top: 50px;
      padding-bottom: 30px;
	`;

	const CopyRightContainer = styled('div')`
      height: 50px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: start;
      align-items: center;

      & * {
        text-align: center;
      }
	`;

	const SocialMediaHolder = styled('div')`
      display: flex;
      gap: 24px;
	  color: white;
	  
	  & * {
		font-size: 36pt;
	  }
	`;

	return (
		<MainContainer>
			<InnerContainer className={"container"}>

				<div style={{cursor: "pointer", maxWidth: "33.333%"}}>
					<NavLink to={"/"}><img src={"/logo-wide.png"} alt={"company logo"} style={{height: 60}}/></NavLink>

					<p style={{color: "#fffc"}}>
						The green climate solutions was founded by highly trained professional Agents to deliver the exceptional quality services you deserve.</p>
				</div>

				<div>
					<h2>
						Navigation
					</h2>

					<div style={{display: "flex", flexDirection: "column", gap: 8}}>
						<NavLink to={"projects"}>
							Projects
						</NavLink>

						<NavLink to={"about"}>
							About Us
						</NavLink>

						<NavLink to={"contact"}>
							Contact Us
						</NavLink>
					</div>
				</div>

				<div>
					<h2>
						Social Media
					</h2>

					<SocialMediaHolder>
						<a href={"https://www.facebook.com"} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faFacebook} /></a>
						<a href={"https://www.instagram.com"} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
						<a href={"https://eg.linkedin.com"} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin}/></a>
					</SocialMediaHolder>
				</div>

			</InnerContainer>

			<CopyRightContainer>
				<div className={"container"}>
					<span>Copyright 2024 - Green Climate Solutions</span>
				</div>
			</CopyRightContainer>
		</MainContainer>
	);
}

export default Footer;
